import type { ComponentPropsWithoutRef } from "react";
import { RiArrowRightCircleFill } from "react-icons/ri";

import newletterBgDesktop from "~/assets/images/pages/multiple-pages/newsletter-desktop.jpg";
import newletterBgMobile from "~/assets/images/pages/multiple-pages/newsletter-mobile.jpg";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import { Image } from "~/components/ui/image";
import { Text } from "~/components/ui/typography";
import { cn } from "~/utils/classnames";

export function NewsletterBlock({ className, ...props }: ComponentPropsWithoutRef<typeof Card>) {
  return (
    <Card
      className={cn(
        "relative grid overflow-hidden border-none px-8 py-8 text-center shadow-none sm:grid-cols-2 sm:text-left md:px-16 lg:px-24",
        className
      )}
      {...props}
    >
      <Image
        src={newletterBgDesktop}
        breakpoints={{
          sm: newletterBgMobile,
        }}
        alt="Inscription à la newsletter"
        className="object-cover max-sm:object-[40%]"
        containerClassName="absolute inset-0"
      />
      <div className="relative">
        <Text size="2xl" className="font-medium">
          Inscrivez-vous à notre newsletter.
        </Text>
        <Text size="2xl" className="font-normal">
          Pour ne pas manquer les Super News !
        </Text>
        <Button
          as="link"
          to="https://eaf2ead2.sibforms.com/serve/MUIFABFuBL_QXr15rizG2H3FcZmRlNlSmCFxhQtaNr6ouWUOjrccf8mHIBFQYGYpJW4hWCplBR_tJWuocBVa99y9Fx0GXTyFakjAHK9AVFLb2u0TIbG8Tai8DiBtELTgr-ZIKSbVSawq5VbB8HwVXIOugT62cRpb-eSmx7a37HfhAB-gZETfxupcHzmPOFZaebD7BwjNQYsO4dLS"
          target="_blank"
          rel="noopener noreferrer"
          variant="outline"
          icon={<RiArrowRightCircleFill />}
          iconPosition="right"
          className="mt-4 border-2 font-semibold"
        >
          Je m&apos;inscris
        </Button>
      </div>
      <div />
    </Card>
  );
}
